import styles from "../css/eventItem.module.css";
import { FaExternalLinkAlt } from "react-icons/fa";
const EventItem = ({ desc,link, date, location }) => {
  // const { desc, date } = props.news.data;
  return (
    <div className={styles.eventItem}>
      <div className={styles.eventInfo}><a href={link} target="_blank"> {desc} <FaExternalLinkAlt /></a></div>
      <p className={styles.eventDate}>{date}</p>
      <p className={styles.location}>{location}</p>
    </div>
  );
};
export default EventItem;

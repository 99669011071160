import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import styles from "../css/home.module.css";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import { Helmet } from "react-helmet-async";

// Data
import { pastEvents } from "../data/pastEvents";
import { latestNews } from "../data/latestNews";
// import { TypeAnimation } from "react-type-animation";
// import biotechImg from "../assets/images/biotech.jpg";
// import allTeamImg from "../assets/images/allTeamImg.jpg";
// import allCoordieImg from "../assets/images/allCoordieImg.jpg";
// import carouselImg from "../assets/images/bsbeBuilding.jpg";
// import hodImg from "../assets/Prof/hod.jpg";
// import facultyConvenorImg from "../assets/Prof/facultyConvenor.png";
import lauruslablogo from "../assets/logo/lauruslablogonb.png";
import iitklogo from "../assets/logo/iitklogonb.png";
import ecelllogo from "../assets/logo/ecelllogonb.png";
import bayer from "../assets/logo/bayer.png";
import dst from "../assets/logo/dst.jpg";
import indiaAlliance from "../assets/logo/india-alliance-dbt-welcome.png";
import ppmd from "../assets/logo/ppmd.png";
import inserb from "../assets/logo/inserb-india.gif";
import dbt from "../assets/logo/dbt.png";
import icmr from "../assets/logo/icmr.png";
import {
  // Button,
  NewsCard,
  EventItem,
} from "../components";
import { getAllDocs } from "../config";
// import { BiMessageDetail } from "react-icons/bi";
// import { BsFillCalendar3EventFill } from "react-icons/bs";
import { MdEventAvailable, MdEvent } from "react-icons/md";
import {
  TbSquareChevronLeftFilled,
  TbSquareChevronRightFilled,
} from "react-icons/tb";
import { ImNewspaper } from "react-icons/im";
// import { BiSolidChevronDown } from "react-icons/bi";
import { FaHandshake } from "react-icons/fa";
import { LuGalleryHorizontalEnd } from "react-icons/lu";
import GalleryCard from "../components/GalleryCard";
const Home = () => {
  const [eventsData, setEventsData] = useState([]);
  const getEvents = async () => {
    let data = await getAllDocs("events");
    if (data != null) {
      setEventsData(data);
    }
  };

  const [newsCardData, setNewsCardData] = useState([]);
  const getNews = async () => {
    let data = await getAllDocs("news");
    if (data != null) {
      setNewsCardData(data);
    }
  };

  useEffect(() => {
    getEvents();
    getNews();
    getGallery();
    const leftBtn1 = document.getElementById("left1");
    const rightBtn1 = document.getElementById("right1");
    const newsCardContainer1 = document.getElementById("newsCardContainer1");
    const leftBtn2 = document.getElementById("left2");
    const rightBtn2 = document.getElementById("right2");
    const newsCardContainer2 = document.getElementById("newsCardContainer2");

    leftBtn1.onclick = () => {
      newsCardContainer1.scrollLeft -= 320;
    };
    rightBtn1.onclick = () => {
      newsCardContainer1.scrollLeft += 320;
    };
    leftBtn2.onclick = () => {
      newsCardContainer2.scrollLeft -= 320;
    };
    rightBtn2.onclick = () => {
      newsCardContainer2.scrollLeft += 320;
    };
  }, []);

  const [gallery, setGallery] = useState([]);
  const getGallery = async () => {
    let data = await getAllDocs("galleryHighlights");
    if (data != null) {
      setGallery(data);
    }
  };
  const logos = [
    lauruslablogo,
    iitklogo,
    ecelllogo,
    dbt,
    indiaAlliance,
    inserb,
    dst,
    ppmd,
    icmr,
    bayer,
  ];
  // const videoId=`wUcDNNbeWqw`;
  // const url = `https://www.youtube.com/embed/wUcDNNbeWqw?rel=0`;
  return (
    <>
      <Helmet>
        <title>
          BioSoc IITK | Society of Biological Sciences & Bioengineering{" "}
        </title>
        <meta
          name="description"
          content="BioSoc (Society of Department of Biological Sciences &
              Bioengineering IIT Kanpur is a non-profit organization. Its primary objective is to
              establish a strong bond among BSBE students, alumni, and
              professors at IIT Kanpur."
        />
        <link rel="canonical" href="/aboutus" />
      </Helmet>
      <div className={styles.home}>
        {/* <NavbarDesktop /> */} {/*Already included in App*/}
        <Carousel
          autoPlay={true}
          interval={3000}
          stopOnHover={false}
          infiniteLoop={true}
          showStatus={false}
          showThumbs={false}
          className="carousel"
        >
          <div className={styles.homeContainer1}>
            <div className={styles.homeMiddle}>
              <h1 className={styles.middleHeader}>
                Discover {/* <span className={styles.genius}>S</span>oc */}
              </h1>
              {/* <p className={styles.middleText}>Cracking the Code of Nature.</p> */}
            </div>
          </div>
          <div className={styles.homeContainer2}>
            <div className={styles.homeMiddle}>
              <h1 className={styles.middleHeader}>
                Learn {/* <span className={styles.genius}>I</span>psum */}
              </h1>
              {/* <p className={styles.middleText}>
                Your Gateway to Biological Breakthroughs
              </p> */}
            </div>
          </div>
          <div className={styles.homeContainer3}>
            <div className={styles.homeMiddle}>
              <h1 className={styles.middleHeader}>
                Innovate {/* <span className={styles.genius}>I</span>psum */}
              </h1>
              {/* <p className={styles.middleText}>
                Advancing Biological Engineering Together
              </p> */}
            </div>
          </div>
        </Carousel>
        {/* <div className={styles.homeBottom}></div>
        <div className={styles.homeBottomOver}></div> */}
        <h1 className={styles.homeHeader}>
              {/* ABOUT <span>BIOSOC</span> */}
              BioSoc: The Platform for Growth and Innovation in BSBE, IIT Kanpur
            </h1>
        <div className={styles.aboutContainer}>
          <div className={styles.aboutText}>
            
            <p className={styles.aboutInfo}>
              <span>
                BioSoc, Society of Biological Sciences & Bioengineering (BSBE)
                Department IIT Kanpur
              </span>{" "}
              is a non-profit organization dedicated to uniting BSBE
              students, alumni, and faculty at IIT Kanpur. We promote open
              communication and idea exchange, creating a collaborative platform
              for all members. With strong support from passionate students and
              faculty, BioSoc is committed to growing its influence and driving
              innovation within the BSBE community.
            </p>

            <Link to="/aboutus">
              <button className={styles.aboutButton}>Know More</button>
            </Link>
          </div>
          <div className={styles.videoWrapper}>
            <figure>
              <figcaption>
                <iframe
                  className={styles.videoIframe}
                  // width="350"
                  // height="197"
                  width="560"
                  height="315"
                  // frameborder="0"
                  // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  // referrerpolicy="strict-origin-when-cross-origin"
                  // allowfullscreen
                  src="https://www.youtube.com/embed/AB4M6VQIvlY?autoplay=1&mute=1"
                  title="YouTube video player"
                  // frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                ></iframe>
                <p>Our BSBE Department Highlights Video| IIT Kanpur </p>
              </figcaption>
            </figure>
          </div>
        </div>
        {/* Commenting for now till actual data comes */}
        {/* <div className={styles.message}>
          <h1 className={styles.messageHeader}>
            MESSAGE FROM <span>FACULTY</span>
            <span className={styles.headerIcon}>
              <BiMessageDetail />
            </span>
          </h1>
          <div className={styles.messageContainer}>
            <div className={styles.messageCard}>
              <div className={styles.messageImage}>
                <img className={styles.profImg} src={hodImg} alt="HOD"></img>{" "}
              </div>
              <div className={styles.messageText}>
                <h3 className={styles.profName}>Dr. Amitabha Bandyopadhyay</h3>
                <p className={styles.profDesignation}>HOD</p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias
                voluptate sequi, placeat doloremque laborum eos velit quis
                dolorem deleniti voluptas ad? Voluptatum at ipsa harum in,
                facilis ut officia.
              </div>
            </div>
            
            <div className={styles.messageCard}>
              <div className={styles.messageImage}>
                <img
                  className={styles.profImg}
                  src={facultyConvenorImg}
                  alt="faculty convenor"
                ></img>{" "}
              </div>
              <div className={styles.messageText}>
                <h3 className={styles.profName}>Dr. Arjun Ramakrishna</h3>
                <p className={styles.profDesignation}>Faculty Convener</p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias
                voluptate sequi, placeat doloremque laborum eos velit quis
                dolorem deleniti voluptas ad? Voluptatum at ipsa harum in,
                facilis ut officia vero.
              </div>
            </div>
          </div>
        </div> */}
        {/* sections start */}
        <div className={styles.sections}>
          <div className={styles.upcomingEvents}>
            <h1 className={styles.upcomingEventsHeader}>
              UPCOMING <span>EVENTS</span>{" "}
              <span className={styles.headerIcon}>
                <MdEvent />
              </span>
            </h1>
            {/* <button className={styles.viewAllBtn}>Past Events</button> */}
            <div className={styles.eventsContainer}>
            <EventItem
                desc="informal interaction with Mr.Gautam Khanna, an IITK alumnus and CEO of Hinduja Hospital"
                link="https://www.instagram.com/p/DG2pwWrtlhO/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
                date="8 March 2025 (10:00 am to 11:00 am)"
                location="PBCEC, VH1(Visitor's Hostel 1), IIT Kanpur"
              />
              <EventItem
                desc="BSBE Department Open House 2024 For New UG and PG Students"
                date="7 September 2024"
                location="MFCEM SEMINAR ROOM, IIT Kanpur"
              />
              <EventItem
                desc="BSBE Fresher's Social 2024 For New UG and PG Students"
                date="17 August 2024"
                location="MFCEM SEMINAR ROOM, IIT Kanpur"
              />
            </div>
          </div>

          <div className={styles.newsContainer}>
            <h1 className={styles.newsHeader}>
              LATEST <span>NEWS</span>{" "}
              <span className={styles.headerIcon}>
                <ImNewspaper />
              </span>
            </h1>

            <button className={styles.viewAllBtn}>
              <Link to="/Blogs">View All</Link>
            </button>

            <div className={styles.newsCardContainer} id="newsCardContainer1">
              {latestNews.map((news, index) => {
                return (
                  <NewsCard
                    key={index}
                    img={news.img}
                    desc={news.desc}
                    link={news.link}
                  />
                );
              })}
            </div>
            <span className={styles.sliderLeft} id="left1">
              <TbSquareChevronLeftFilled />
            </span>
            <span className={styles.sliderRight} id="right1">
              <TbSquareChevronRightFilled />
            </span>
          </div>

          <div className={styles.newsContainer}>
            <h1 className={styles.newsHeader}>
              PAST <span>EVENTS</span>{" "}
              <span className={styles.headerIcon}>
                <MdEventAvailable />
              </span>
            </h1>

            <button className={styles.viewAllBtn}>
              <Link to="/events">View All</Link>
            </button>
            <div className={styles.newsCardContainer} id="newsCardContainer2">
              {pastEvents.map((event, index) => {
                return (
                  <NewsCard
                    key={index}
                    img={event.img}
                    desc={event.desc}
                    link={event.link}
                  />
                );
              })}
            </div>
            <span className={styles.sliderLeft} id="left2">
              <TbSquareChevronLeftFilled />
            </span>
            <span className={styles.sliderRight} id="right2">
              <TbSquareChevronRightFilled />
            </span>
          </div>

          {/* Gallery Heighlights */}
          <div className={styles.galleryContainer}>
            <h1 className={styles.galleryHeader}>
              Captured <span>Moments</span>{" "}
              <span className={styles.headerIcon}>
                <LuGalleryHorizontalEnd />
              </span>
            </h1>
            <button className={styles.viewAllBtn}>
              <Link to="/gallery">explore more</Link>
            </button>
            <div className={styles.galleryList}>
              {gallery.slice(2, 9).map((gallery, index) => (
                <GalleryCard
                  key={index}
                  img={gallery.data.img}
                  info={gallery.data.info}
                />
              ))}
            </div>
          </div>

          <div className={styles.collaboration}>
            <h1 className={styles.collaborationHeader}>
              OUR <span>COLLABORATION</span>{" "}
              <span className={styles.headerIcon}>
                <FaHandshake />
              </span>
            </h1>
            <button className={styles.viewAllBtn}>
              <Link to="/Collaborations">View In Detail</Link>
            </button>
            <div className={styles.scrollAnimation}>
              {logos.concat(logos).map((logo, index) => (
                <img
                  key={index}
                  src={logo}
                  alt={`Partner ${index + 1}`}
                  className={styles.partnerlogo}
                />
              ))}
            </div>
          </div>
        </div>
        {/* sections end */}
        <div className={styles.bottomImg}>
          <div className={styles.upperGradient}></div>
          <div className={styles.upperGradientOver}></div>
        </div>
      </div>
    </>
  );
};

export default Home;

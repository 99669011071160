import deepakModi from '../assets/events/deepak-modi.jpg'
import entrepreneursFirst from  '../assets/events/EntrepreneurFirst.jpg'
import eveningWithSrikant from '../assets/events/An-Inspiring-Evening-with-Mr-Srikant-Sastri.jpg'
import startupRashieJain from '../assets/events/Startups-Rashi-Jain.jpg'
import convocation23 from '../assets/events/convocation23.jpg'
import fresher23 from '../assets/events/fresher23.jpg'
import mfcemiitk from '../assets/events/MFCEM-at-IIT-Kanpur.jpg'
import gautamkhanna from '../assets/events/Gautam-Khanna.jpg'
 export const pastEvents = [
    {
        img:gautamkhanna,
        desc: "Informal interaction with Mr.Gautam Khanna, an IITK alumnus and CEO of Hinduja Hospital",
        link:"https://www.linkedin.com/posts/doraiitkanpur_healthcareinnovation-leadership-changemanagement-activity-7304746425135886337-UfN8/?utm_source=social_share_send&utm_medium=android_app&rcm=ACoAADTkP6IBqrpR32a-6QpuSgGJO_1Ez5qNKAM&utm_campaign=share_via"
    },
    {
        img:mfcemiitk,
        desc: "IIT Kanpur inaugurates Mehta Family Centre for Engineering in Medicine",
        link:"https://mehtafamilyfoundation.org/iit-kanpur-inauguration-in-the-news/"
    },
    {
        img:deepakModi,
        desc: "Let's Talk about Sex with a distinguished geneticist Dr. Deepak Modi.",
        link:"https://www.instagram.com/p/CyBiJqIN0W6/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
    },
    {
        img:entrepreneursFirst,
        desc:"Exceptional People Should Not Get Jobs | BioSoc IITK X Ecell IITK",
        link:"https://www.instagram.com/p/Cx0lamJN9nL/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
    },
    {
        img:eveningWithSrikant,
        desc:"An Inspiring Evening with Mr. Srikant Sastri",
        link:"https://www.instagram.com/p/CxtQLEjrkni/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
    },
    {
        img:startupRashieJain,
        desc:"Startups: How To Start, Sustain and Succeed | Session with Rashie Jain",
        link:"https://www.instagram.com/p/CwX6snLNiJ-/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
    },
    {
        img:fresher23,
        desc:"Fresher's Social For UG And PG Y23 | BSBE IITK",
        link:"https://www.instagram.com/p/Cv91uF4tEg3/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
    },
    {
        img:convocation23,
        desc:"56th Convocation | Department of Biological Sciences and Bioengineering",
        link:"https://www.instagram.com/p/CuWf3u7xbIV/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
    }

]